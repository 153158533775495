<template>
  <div class="coutent">
    <div class="flex">
      <!-- <div class="new-phoneImg phone1">
        <img src="../../photo/new/云录制面试.gif" alt="" class="new-phoneImgGif" />
      </div>
      <a!-- <img
        src="../../photo/iphone.png"
        alt=""
        class="phoneImg2"
        style="margin-right: 0"
      /> --a>
      <img src="../../photo/decoration-circle.png" alt="" class="faceImage1" /> -->
      <div class="phone-new1">
        <img class="img-new1" src="../../photo/new/云录制面试-电脑.gif" alt="">
      </div>
    </div>
    <div class="flex">
      <div class="coutentRight1">
        <img src="../../photo/text-云录制面试.png" alt="" class="testImage0" />
        <div class="RightText1">一次面试，丝滑推荐多次<br>极速入职如您所愿。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countentPage1',
  components: {},
  methods: {},
}
</script>

<style lang="scss" socped>
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  flex: 1;
}
.phone-new1{
  max-width: 98%;
  margin-top: 13%;
  text-align: right;
}
.img-new1 {
    min-width: 400px;
    max-height: 780px;
    max-width: 70%;
  }
.new-phoneImg {
  width: 584px;
  height: 730px;
  position: relative;
  background-image: url('../../photo/iphone.png');
  background-size: cover;
}
.new-phoneImg.phone1 {
  margin-top: 184px;
  margin-left: 357px;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 116px;
  margin-right: 106px;
  width: 225px;
  /*height: 464px;*/
  position: relative;
}
.phoneImg2 {
  margin-top: 184px;
  margin-left: 357px;
  width: 584px;
  height: 730px;
  position: relative;
}
.faceImage1 {
  width: 117px;
  height: 114px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 190px;
  left: 375px;
}
.testImage0 {
  margin-top: 38%;
}
.RightText1 {
  /*width: 352px;*/
  /*height: 45px;*/
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 40px;
  margin-top: 20px;
   /* margin-left: 73px;
  margin-bottom: 321px;*/
  text-align: left;
}
.iconStyle {
  width: 16px;
  height: 18px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.textStyle {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0e1a2d;
  line-height: 25px;
}
.textStyleRight {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;

  line-height: 25px;
  -webkit-background-clip: text;
}
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
.header {
  height: 88px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid rgba(14, 26, 45, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  box-sizing: border-box;
}
.headerCoutent {
  margin: 0 auto;
  height: 88px;
  width: 1244px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.logoStyle {
  width: 40px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.coutent {
  height: 100% !important;
  width: 100%;
  padding-top: 88px;
  background: #ffffff;
  display: flex;
  overflow: hidden;
  margin-bottom: 120px;
}
.coutentRight1 {
  height: 100%;
  background-image: url('../../photo/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: left;
}
.fotter {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
