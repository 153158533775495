<template>
  <div class="About">
    <div class="header1">
      <div class="headerCoutent textStyle">
        <div class="header-left displayFlex textStyle">
          <img
            src="../../photo/logo@2x.png"
            alt=""
            class="logoStyle"
            style="margin-right: 16px"
          />
          荐神APP
        </div>
        <div class="header-right">
          <span
            style="margin-right: 64px"
            class="abouttextStyle"
            @click="() => $router.push('/')"
          >
            首页
          </span>
          <span class="textStyleRight" @click="() => $router.push('/about')">
            关于我们
            <div class="active2"></div>
          </span>
        </div>
      </div>
    </div>
    <div class="coutent1">
      <div class="textPhoto">
        <img
          src="../../photo/text-做一个有温度.png"
          alt=""
          srcset=""
          class="doImg"
        />
        <!-- <img src="../../photo/iphone.png" alt="" class="phoneImgAbout" /> -->
        <div class="new-phoneImgAbout">
          <img src="../../photo/new/荐神-关于我们-电脑.gif" alt="" class="new-phoneImgGif" />
        </div>
        <!-- <img
          src="../../photo/decoration-boy.png"
          alt=""
          class="position boyImg"
        />
        <img
          src="../../photo/decoration-ok.png"
          alt=""
          class="position okImg"
        />
        <img
          src="../../photo/decoration-phone.png"
          alt=""
          class="position ptellPhoneImg"
        /> -->
      </div>
      <div class="aboutInfo">
        <span class="spanText">关于我们</span>
        <!-- <img
          src="../../photo/decoration-green strap.png"
          alt=""
          class="green"
        /> -->
        <div class="displayFlexCoutent">
          <span class="displayFlexCoutent-text">
            “荐神”是一个致力于“极速成功入职”为目标的、按效果付费的求职招聘平台。它开放所有人才库，全新的职位曝光算法，多功能精准搜索，并以“云录制面试”方式，做到            
            一次沟通多次精准推荐，极大限度降低企业、能人的成本。
          </span>
          <!-- <span class="displayFlexCoutent-text"> -->
            <!-- 一次沟通多次精准推荐，极大限度降低企业、能人的成本。 -->
          <!-- </span> -->
        </div>
      </div>
      <div class="aboutInfo">
        <span class="spanText t2" style="margin-top: 160px">角色</span>
        <!-- <img
          src="../../photo/decoration-green strap.png"
          alt=""
          class="green1"
        /> -->

        <div class="aboutInfoFlexBox" style="margin-top: 40px">
          <div class="aboutInfoFlexBox-coutent">
            <img
              src="../../photo/icon-荐神.png"
              alt=""
              class="aboutInfoImage"
            />
            <div class="displayFlexCoutent-text fontsize" style="width: 100%">
              深耕多年的职场精英，精准评估与指导，全程守护求职、招聘进程
            </div>
          </div>
          <div class="aboutInfoFlexBox-coutent">
            <img
              src="../../photo/icon-企业.png"
              alt=""
              class="aboutInfoImage"
            />
            <span class="displayFlexCoutent-text fontsize" style="width: 100%">
              随时、按需购买，按招聘效果付费。
            </span>
          </div>
          <div class="aboutInfoFlexBox-coutent" style="margin-right: 0">
            <img
              src="../../photo/icon-能人.png"
              alt=""
              class="aboutInfoImage"
            />
            <span class="displayFlexCoutent-text fontsize" style="width: 100%"
              >即求职者。多位荐神助力寻找工作机会，7*24小时精准推荐岗位，不错过任何一个工作的机会。
            </span>
          </div>
        </div>
      </div>
      <div class="aboutInfo">
        <span class="spanText" style="margin-top: 160px">主要功能</span>
        <!-- <img
          src="../../photo/decoration-green strap.png"
          alt=""
          class="green2"
        /> -->
        <div class="aboutInfoFlexBox" style="margin-top: 40px">
          <div class="aboutInfoFlexBox-coutent">
            <img
              src="../../photo/icon-云录制面试.png"
              alt=""
              class="aboutInfoImage"
            />
            <div class="displayFlexCoutent-text fontsize" style="width: 100%">
              面试经授权后会被录制，作为“一次面试，多次精准推荐”的依据
            </div>
          </div>
          <div class="aboutInfoFlexBox-coutent">
            <img
              src="../../photo/icon-荐神报告.png"
              alt=""
              class="aboutInfoImage"
            />
            <span class="displayFlexCoutent-text fontsize" style="width: 100%"
              >云录制面试后的荐神记录，对能人求职意向、沟通、专业等能力的评估报告
            </span>
          </div>
          <div class="aboutInfoFlexBox-coutent" style="margin-right: 0">
            <img
              src="../../photo/icon-推荐ta.png"
              alt=""
              class="aboutInfoImage"
            />
            <span class="displayFlexCoutent-text fontsize" style="width: 100%"
              >依据荐神报告，撰写推荐信，批量推荐多个精准职位，加速推荐效率
            </span>
          </div>
        </div>
      </div>
      <div class="aboutInfo1" style="margin-top: 80px">
        <div class="aboutInfoFlexBox1">
          <div class="aboutInfoFlexBox-coutent1">
            <img
              src="../../photo/icon-结算.png"
              alt=""
              class="aboutInfoImage"
            />
            <div class="displayFlexCoutent-text fontsize" style="width: 100%">
              面试、入职两个节点，荐神分别获得大量推荐奖励
            </div>
          </div>
          <div class="aboutInfoFlexBox-coutent1">
            <img
              src="../../photo/icon-消息.png"
              alt=""
              class="aboutInfoImage"
            />
            <div class="displayFlexCoutent-text fontsize" style="width: 100%">
              和职场大神深聊、深深地聊
            </div>
          </div>
        </div>
      </div>
      <div class="aboutInfo" style="margin-top: 160px">
        <span class="spanText">联系我们</span>
        <!-- <img
          src="../../photo/decoration-green strap.png"
          alt=""
          class="green3"
        /> -->
        <div class="displayFlexCoutent">
          <img src="../../photo/QR.png" alt="" style="margin-top: 40px" />
        </div>
        <span
          class="displayFlexCoutent-text"
          style="font-size: 18px; margin-bottom: 174px"
        >
          微信扫码
        </span>
      </div>
    </div>

    <FooterView changeHeader="header"></FooterView>
  </div>
</template>

<script>
import FooterView from '@/components/footer.vue'
export default {
  name: 'HeaderView',
  components: { FooterView },
  methods: {},
}
</script>

<style>
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.boyImg {
  right: 28%;
  top: 79%;
}
.active2 {
  display: inline-block;
  position: relative;
  left: -18.8%;
  bottom: -15px;
  transform: translateX(-50%);
  width: 24px;
  height: 6px;
  background: #fff;
  border-radius: 3px;
  width: 24px;
  height: 6px;
  background: #06bf6d;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
}
.aboutInfoFlexBox1 {
  display: flex;
  justify-content: space-between;
  padding: 0 218px;
}
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  flex: 1;
}
.fontsize {
  font-size: 22px !important;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
}
.aboutInfoFlexBox {
  display: flex;
}
.aboutInfoImage {
  width: 132px;
  height: 132px;
}
.aboutInfoFlexBox-coutent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 356px;
  margin-right: 136px;
}
.aboutInfoFlexBox-coutent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 356px;
}

.spanText {
  width: 180px;
  height: 90px;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0e1a2d;
  line-height: 90px;
  position: relative;
  text-align: center;
  background-image: url('../../photo/decoration-green strap.png');
  background-repeat: no-repeat;
  background-position: 0px 38px;
  padding-left:20px
}
.spanText.t2{
  background-position: 40px 38px;

}
.green {
  position: absolute; 
  left: 340px; 
  top: 39px;
}
.green1 {
  position: absolute;
  left: 380px; 
  top: 200px;
}
.green2 {
  position: absolute;
  left: 340px; 
  top: 200px;
}
.green3 {
  position: absolute;
  left: 340px; 
  top: 39px;
}
.position {
  position: absolute;
}
.aboutInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.displayFlexCoutent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.displayFlexCoutent-text {
  width: 1109px;
  font-size: 29px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 16px;
  color: #78879c;
  text-align: center;
}
.okImg {
  left: 33%;
  top: 95%;
}
.ptellPhoneImg {
  left: 29%;
  top: 665px;
}
.doImg {
  width: 943px;
  /* height: 214px; */
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  max-width: 100%;
  min-width: 50%;
}

.new-phoneImgAbout {
  width: 100%;
  text-align: center;
  /*background-image: url('../../photo/bg.png');
  background-repeat: no-repeat;
  background-position: 12% 29%;
  background-size: 25%;*/
}
.new-phoneImgAbout .new-phoneImgGif {
  min-width:450px;
  max-width:930px;
  width:55%;
}
.phoneImgAbout {
  width: 525px;
  height: 693px;
  position: relative;
  margin-left: 58px;
}
.textPhoto {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.faceImage {
  width: 117px;
  height: 114px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 230px;
  right: 1060px;
}
.testImage {
  width: 538px;
  height: 90px;
  margin-left: 66px;
  margin-top: 369px;
}
.RightText {
  width: 576px;
  height: 45px;
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 38px;
  margin-top: 33px;
  margin-left: 66px;
}
.iconStyle {
  width: 16px;
  height: 18px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.textStyle {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0e1a2d;
  line-height: 25px;
}
.abouttextStyle {
  width: 36px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 25px;
}
.textStyleRight {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0e1a2d;
  line-height: 25px;
  -webkit-background-clip: text;

  position: relative;
}
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
.header1 {
  height: 88px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid rgba(14, 26, 45, 0.2);
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerCoutent {
  margin: 0 auto;
  height: 88px;
  width: 1244px;
  display: flex;
  align-items: center;
}

.header-left {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.logoStyle {
  width: 40px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.coutent1 {
  width: 100%;
  height: 100% !important;
  padding: 169px 290px 0 290px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: auto;
}

.fotter {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  position: relative !important;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.About {
  overflow: hidden;
}
</style>
