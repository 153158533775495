<template>
  <div class="socped" :style="{ height: viewHeight }">
    <div :class="$props.index == 1 ? 'countenthome bgc' : 'countenthome '">
      <video autoplay loop muted class="videohome">
        <source src="../../photo/new/clip.mp4" type="video/mp4">
      </video>
      <!-- 第一页 -->
      <div
        class="coutentBox"
        v-if="$props.index == 1"
        :style="computedBoxStyle"
      >
        <img src="../../photo//text-能者.png" alt="" />
        <div style="text-align: center">
          <div class="coutentBoxTxt">有温度的传承式求职招聘平台</div>
          <div class="downloadBox">
            <div
              style="margin-right: 32px"
              class="coutentDownload"
              @mouseover="handelHover('apple')"
              @mouseleave="handelLeave('apple')"
            >
              <img
                src="../../photo//icon-apple.png"
                alt=""
                srcset=""
                class="appleImage"
              />
              <div>
                <img
                  src="../../photo//text-download.png"
                  alt=""
                  srcset=""
                  class="downLoadText"
                />
                苹果市场
              </div>
              <img
                src="../../photo/erCode.png"
                alt=""
                class="erCode"
                v-if="dialogShow.apple"
              />
            </div>
            <div
              class="coutentDownload"
              @mouseover="handelHover('android')"
              @mouseleave="handelLeave('android')"
            >
              <img
                src="../../photo//icon-android.png"
                alt=""
                srcset=""
                class="appleImage"
              />
              <div>
                <img
                  src="../../photo//text-download.png"
                  alt=""
                  srcset=""
                  class="downLoadText"
                />
                <div>安卓市场</div>
                <img
                  src="../../photo/erCode.png"
                  alt=""
                  class="erCode1"
                  v-if="dialogShow.android"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二页 -->
      <countentPage4 v-if="$props.index == 4"></countentPage4>
      <!-- 第三页 -->
      <countentPage1 v-if="$props.index == 2"></countentPage1>
      <!-- 第四页 -->
      <countentPage2 v-if="$props.index == 3"></countentPage2>
      <!-- 第五页 -->
      <countentPage3 v-if="$props.index == 5"></countentPage3>
    </div>
  </div>
</template>

<script>
import countentPage1 from '@/components/countentPage1.vue'
import countentPage2 from '@/components/countentPage2.vue'
import countentPage3 from '@/components/countentPage3.vue'
import countentPage4 from '@/components/countentPage4.vue'
export default {
  name: 'CouytentView',

  props: {
    index: {
      default: null,
    },
  },
  components: { countentPage1, countentPage2, countentPage3, countentPage4 },
  data() {
    return {
      dialogShow: {
        apple: false,
        android: false,
      },
      viewHeight: document.documentElement.scrollHeight,
    }
  },
  created() {},

  methods: {
    handelHover(type) {
      switch (type) {
        case 'apple':
          console.log(1231)
          this.dialogShow[type] = true
          break
        case 'android':
          console.log(12312131231)
          this.dialogShow[type] = true
          break
      }
    },
    handelLeave(type) {
      console.log(type)
      switch (type) {
        case 'apple':
          this.dialogShow[type] = false
          break
        case 'android':
          this.dialogShow[type] = false
          break
      }
    },
  },
  computed: {
    computedBoxStyle() {
      console.log(1231)
      return {
        height: this.viewHeight,
      }
    },
  },
}
</script>

<style lang="scss" socped>
body {
  height: 100%;
  width: 100%;
}
.socped {
  height: 955px;
  .erCode {
    width: 180px;
    height: 180px;
    position: relative;
    bottom: -118%;  
    left: -90.04%;
  }
  .erCode1 {
    left: -44.5%; 
    width: 180px;
    height: 180px;
    position: relative;
    bottom: -46.15%;
  }
  .appleImage {
    height: 36px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-left: 30px;
    margin-top: 10px;
    margin-right: 22px;
  }
  .bgc {
    width: 100%;
    height: 100%;
    // background-image: url('../../photo/bg-web.png');
  
    .videohome{
      display: block;
    }
  }
  .videohome {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: none;
    background-image: url('../../photo/new/video-bg.png');
    background-position: center center;
    background-size: cover;
}
  
  .downLoadText {
    width: 100px;
    height: 6px;
    margin-top: 12px;
  }
  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconStyle {
    width: 16px;
    height: 18px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .textStyle {
    width: 36px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 25px;
  }
  .textStyleRight {
    width: 72px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    -webkit-background-clip: text;
  }
  .countenthome {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coutentBox {
    display: flex;
    flex-direction: column;
  }
  .coutentBoxTxt {
    font-size: 32px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    margin: 46px 0 64px 0;
  }
  .downloadBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coutentDownload {
    width: 196px;
    height: 64px;
    background: #090914;
    color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #ffffff;
    text-align: left;
    display: flex;
  }
}
</style>
