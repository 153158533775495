<template>
  <div>
    <div :class="isHome ? 'headerHoem' : 'header'">
      <div class="headerCoutent">
        <div
          :class="
            isStyle != 1
              ? ' header-left displayFlex isStyle'
              : ' header-left displayFlex'
          "
        >
          <img
            src="../../photo/logo@2x.png"
            alt=""
            class="logoStyle"
            style="margin-right: 16px"
          />
          荐神APP
        </div>
        <div class="header-right">
          <span
            style="margin-right: 64px"
            :class="
              isStyle != 1
                ? ' hoeme-header-right  isStyle'
                : 'hoeme-header-right'
            "
          >
            首页
            <span class="active1"></span>
          </span>

          <span class="textStyleRight1" @click="() => $router.push('/about')">
            关于我们
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'

// @ is an alias to /src

export default {
  name: 'HeaderView',
  props: {
    isStyle: {
      default: null,
    },
    isHome: {
      default: null,
    },
  },
  components: {},
  methods: {},
}
</script>

<style lang="scss" socped>
.isStyle {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0e1a2d !important;
  line-height: 25px;
}
.header {
  height: 88px;
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
}
.headerHoem {
  height: 88px;
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
}
.headerCoutent {
  margin: 0 auto;
  height: 88px;
  width: 1244px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoStyle {
  width: 40px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.hoeme-header-right {
  position: relative;
  width: 36px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
}
.textStyleRight1 {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 25px;
  color: #aeacab;
}
.active1 {
  display: inline-block;
  position: relative;
  left: -10.8%;
  bottom: -15px;
  transform: translateX(-50%);
  width: 24px;
  height: 6px;
  background: #fff;
  border-radius: 3px;
  width: 24px;
  height: 6px;
  background: #06bf6d;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
}
</style>
