<template>
  <div class="coutent5">
    <div class="flex">
      <!-- <div class="new-phoneImg phone4">
        <img src="../../photo/new/按需发布职位.gif" alt="" class="new-phoneImgGif" />
      </div>
      <img src="../../photo/decoration-face.png" alt="" class="faceImage5" /> -->
      <div class="phone-new4">
        <img class="img-new4" src="../../photo/new/按需发布职位-电脑.gif" alt="">
      </div>
    </div>
    <div class="flex coutentRight5">
        <img src="../../photo/text-按需发布.png" alt="" class="testImage5" />
        <div class="RightText5">职位真实，按需发布<br>坐等准员工入职。</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countentPage1',
  components: {},
  methods: {},
}
</script>

<style>
.flex {
  flex: 1;
}
.phone-new4{
  max-width: 98%;
  margin-top: 13%;
  text-align: right;

}
.img-new4 {
    min-width: 400px;
    max-height: 780px;
    max-width: 70%;
  }
.new-phoneImg {
  width: 584px;
  height: 730px;
  position: relative;
  background-image: url('../../photo/iphone.png');
  background-size: cover;
}
.new-phoneImg.phone4 {
  margin-top: 88px;
  margin-left: 357px;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 116px;
  margin-right: 106px;
  width: 225px;
  /*height: 464px;*/
  position: relative;
}
.phoneImg5 {
  margin-top: 88px;
  margin-left: 357px;
  width: 584px;
  height: 730px;
  position: relative;
}
.faceImage5 {
  width: 117px;
  height: 114px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 230px;
  right: 1060px;
}
.testImage5 {
  margin-top: 37%;
}
.RightText5 {
  /* width: 576px; */
  /* height: 45px; */
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 40px;
  margin-top: 20px;
  /* margin-left: 66px; */
}

.coutent5 {
  /* height: 880px; */
  height: 100%;
  width: 100%;
  padding-top: 88px;
  background: #ffffff;
  display: flex;
}
.coutentRight5 {
  background-image: url('../../photo/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: left;
}
</style>
