<template>
  <div class="coutent">
    <div class="flex coutentRight3">
      <div class="textMs1">
        <img src="../../photo/text-资金管理.png" alt="" class="testImage3" />
        <div class="textMs3">
          <div class="RightText3">荐神协作，加速推荐<br>共飨面试、入职丰厚奖金。</div>
        </div>
      </div>
    </div>
    <div class="flex">
      <!-- <div class="new-phoneImg phone3">
        <img src="../../photo/new/资金管理.gif" alt="" class="new-phoneImgGif" />
      </div>
      <img src="../../photo/decoration-lock.png" alt="" class="keyImage1" /> -->
      <div class="phone-new3">
        <img class="img-new3" src="../../photo/new/资金管理-电脑.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countentPage1',
  components: {},
  methods: {},
}
</script>
<style>
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  flex: 1;
}
.textMs1 {
  margin-left: 37%;
}
.textMs3 {
  margin-top: 22px;
  /* margin-left:0; */
}
.phone-new3{
  max-width: 98%;
  margin-top: 13%;
  text-align: left;

}
.img-new3 {
    min-width: 400px;
    max-height: 780px;
    max-width: 70%;
  }
.new-phoneImg {
  width: 584px;
  height: 730px;
  position: relative;
  background-image: url('../../photo/iphone.png');
  background-size: cover;
}
.new-phoneImg.phone3 {
  margin-top: 106px;
  margin-right: 354px;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 116px;
  margin-right: 106px;
  width: 225px;
  /*height: 464px;*/
  position: relative;
}
.phoneImg4 {
  margin-top: 106px;
  margin-right: 354px;
  width: 584px;
  height: 730px;
  position: relative;
}
.keyImage1 {
  width: 139px;
  height: 139px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 267px;
  right: 810px;
}
.testImage3 {
  /* width: 350px;
  height: 87.55px; */
  margin-top: 55%;
  /* margin-left: -152px; */
}
.RightText3 {
  /* width: 391px; */
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 40px;
  margin-top: 20px;
}
.iconStyle {
  width: 16px;
  height: 18px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.textStyle {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0e1a2d;
  line-height: 25px;
}
.textStyleRight {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;

  line-height: 25px;
  -webkit-background-clip: text;
}

.coutent {
  /* height: 880px; */
  height: 100%;
  width: 100%;
  padding-top: 88px;
  background: #ffffff;
  display: flex;
}
.coutentRight3 {
  background-image: url('../../photo/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: left;
}
</style>
