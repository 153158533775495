<template>
  <div class="coutent">
    <div class="flex coutentRight">
      <div class="textMs">
        <img src="../../photo/text-荐神.png" alt="" class="testImage1" />
        <div >
          <div class="RightText2" >
            多年经验的职场精英<br>精准评估与指导，全程守护求职进程。</div>
        </div>
      </div>
    </div>
    <div class="flex" >
      <!-- <div class="new-phoneImg phone2">
        <img src="../../photo/new/荐神.gif" alt="" class="new-phoneImgGif" />
      </div>
      <img src="../../photo/decoration-finger.png" alt="" class="keyImage" /> -->
      <div class="phone-new2">
        <img class="img-new2" src="../../photo/new/荐神 电脑.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countentPage1',
  components: {},
  methods: {},
}
</script>
<style lang="scss" socped>
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex {
  flex: 1;
}
.textMs {
  margin-top: 22px;
  margin-left: 37%;
  text-align: left;
}
.phone-new2{
  max-width: 98%;
  margin-top: 13%;
  text-align: left;

}
.img-new2 {
    min-width: 400px;
    max-height: 780px;
    max-width: 70%;
  }
.new-phoneImg {
  width: 584px;
  height: 730px;
  position: relative;
  background-image: url('../../photo/iphone.png');
  background-size: cover;
}
.new-phoneImg.phone2 {
  margin-top: 190px;
  margin-right: 354px;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 116px;
  margin-right: 106px;
  width: 225px;
  /*height: 464px;*/
  position: relative;
}
.phoneImg3 {
  margin-top: 190px;
  margin-right: 354px;
  width: 584px;
  height: 730px;
  position: relative;
}
.keyImage {
  width: 153px;
  height: 153px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 250px;
  right: 425px;
}
.testImage1 {
  margin-top: 55%;
  /*margin-left: -388px;*/
}
.RightText2 {
  width: 550px;
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #78879c;
  line-height: 40px;
  margin-top:20px;
}
.iconStyle {
  width: 16px;
  height: 18px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.textStyle {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0e1a2d;
  line-height: 25px;
}
.textStyleRight {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;

  line-height: 25px;
  -webkit-background-clip: text;
}
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
.header {
  height: 88px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid rgba(14, 26, 45, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  box-sizing: border-box;
}
.headerCoutent {
  margin: 0 auto;
  height: 88px;
  width: 1244px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.logoStyle {
  width: 40px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.coutent {
  // height: 880px;
  height: 100%;
  width: 100%;
  padding-top: 88px;
  background: #ffffff;
  display: flex;
}
.coutentRight {
  background-image: url('../../photo/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.fotter {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
