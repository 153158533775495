<template>
  <div>
    <div :class="$props.changeHeader ? 'fotter' : 'footer1'">
      <div class="fotterCountent">
        <div class="fotterTextStyle" style="margin-top: 24px">
          荐神（上海）科技有限公司
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          "
        >
          <span class="fotterTextStyle" @click="handelOpen('wh')" style="text-decoration: underline;">
            沪ICP备2023006285号-1 
          </span>
          <!-- <img src="../../photo/icon-公安.png" alt="" style="margin: 0 8px" /> -->
          <!-- <span class="fotterTextStyle" @click="handelOpen(false)"> -->
            <!--沪公网安备 号-->
          <!-- </span> -->
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="../../photo/icon-email.png"
            alt=""
            style="margin-right: 8px"
          />
          <span class="fotterTextStyle"> 邮箱地址：gavin@ituiu.com </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  props: {
    changeHeader: '',
  },
  components: {},
  methods: {
    handelOpen(type) {
      if (type) {
        window.open('https://beian.miit.gov.cn/')
      } else {
        window.open('https://beian.mps.gov.cn/#/')
      }
    },
  },
}
</script>

<style lang="scss" socped>
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
.fotter {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
}
.footer1 {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
