<template>
  <div class="home">
    <HeaderView
      :isStyle="index"
      :isHome="index == 1 ? 'isHome' : null"
    ></HeaderView>
    <div @wheel="handleScroll" style="height: 100%">
      <countentView :index="index"></countentView>
    </div>

    <FooterView v-if="index == 5"></FooterView>
    <div class="button">
      <ul>
        <li
          v-for="(item, idx) in liStyle"
          :key="idx"
          :class="item == index ? 'active' : ''"
          @click="() => (index = idx + 1)"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/header.vue'
import FooterView from '@/components/footer.vue'
import countentView from '@/components/countent.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      index: 1,
      liStyle: [1, 2, 3, 4, 5],
    }
  },
  components: {
    HeaderView,
    FooterView,
    countentView,
  },
  methods: {
    handleScroll(e) {
      e.preventDefault() // 阻止滚动事件的默认行为
      // if (e.deltaY == 100) {
      //   if (this.index >= 5) {
      //     this.index == 5
      //   } else {
      //     this.index = this.index + 1
      //   }
      // } else {
      //   if (this.index <= 1) {
      //     this.index = 1
      //   } else {
      //     this.index = this.index - 1
      //   }
      // }
      if (e.deltaY < 0) {//向上滚动
        if (this.index <= 1){
          this.index = 1
        }else{
          this.index = this.index - 1
        }
        
      } else {
        if (this.index < 5) {
          this.index = this.index + 1
        }else{
          this.index == 4
        }
      }
    },
  },
}
</script>

<style lang="scss" socped>
html,
body {
  height: 100%;
}
.home {
  height: 100%;
}
.button ul li {
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-bottom: 8px;
  list-style: none;
}
.button {
  position: fixed;
  top: 50%;
  right: 290px;
  z-index: 100;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.active {
  width: 8px !important;
  height: 24px !important;
  background: #06bf6d !important;
  border-radius: 4px 4px 4px 4px !important;
  opacity: 1 !important;
}
</style>
